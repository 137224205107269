<template>
  <div
    class="flex justify-start"
    :class="{ 'pl-1 -ml-2': truncate }"
    @click.stop
  >
    <label
      class="font-medium flex"
      :title="option"
      :class="[
        labelClass,
        { 'overflow-x-hidden': truncate },
        disabled ? 'text-gray-400' : 'text-gray-500 cursor-pointer'
      ]"
    >
      <input
        type="checkbox"
        :class="[
          { 'mr-4': Object.keys($slots).length && !noMargin },
          { 'cursor-pointer': !disabled },
          { 'text-indigo-600': !disabled },
          { 'text-gray-300': disabled },
          { 'mt-1 mb-1 ml-1': truncate }
        ]"
        class="h-4 w-4 border-gray-300 rounded-sm place-self-center"
        :name="name"
        :value="value"
        :checked="isChecked"
        :disabled="disabled"
        :id="String(id)"
        role="checkbox"
        :data-cy="`${name}-checkbox`"
        :indeterminate="indeterminate"
        @change="onChange"
      />
      <span v-if="truncate" class="overflow-hidden text-ellipsis">
        <slot></slot>
      </span>
      <slot v-else></slot>
    </label>
  </div>
</template>

<script setup lang="ts" generic="TValue, TModelValue extends boolean | any[]">
import { useField } from "vee-validate";
import { computed } from "vue";

interface Props {
  name: string;
  value: TValue;
  modelValue: TModelValue;
  disabled?: boolean;
  id?: string | number;
  truncate?: boolean;
  testId?: string;
  option?: string;
  noMargin?: boolean;
  indeterminate?: boolean;
  labelClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  testId: "",
  option: "",
  labelClass: ""
});

const emit = defineEmits<{
  "update:modelValue": [TModelValue];
}>();

const { handleChange } = useField(props.name, undefined, {
  type: "checkbox",
  valueProp: props.value
});

const isChecked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.value);
  }
  return !!props.modelValue;
});

const onChange = (event: Event) => {
  const isChecked = (event.target as HTMLInputElement).checked;

  if (!Array.isArray(props.modelValue)) {
    handleChange(isChecked);
    emit("update:modelValue", isChecked as TModelValue);
  } else {
    const newValue = [...props.modelValue];

    if (isChecked) {
      newValue.push(props.value);
    } else {
      newValue.splice(newValue.indexOf(props.value), 1);
    }

    handleChange(props.value);
    emit("update:modelValue", newValue as TModelValue);
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  &:indeterminate {
    @apply relative w-4 h-4 border-gray-400;
    content: "";
    background: none;

    &::after {
      @apply absolute bg-indigo-600 w-2-5 h-2-5 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
      content: "";
    }
  }
}
</style>
